import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";

// Components
import Icon from "../custom-widgets/icon";
import commonPasswordData from "../../data/common-passwords-data";
import Mellt from "../../helpers/mellt";
import "./password-testing-tool.bootstrap.scss";

const PasswordTestingTool = (props) => {
  // TODO: the design only has 3 levels of messages (success, warning, fail), but we are using 4 categories (Very Strong, Strong, Weak, and Very Weak )
  const messages = {
    length: {
      success: "<strong>Good Password Length</strong>",
      warning: "<strong>Short Password</strong><br/>Try making your password longer.",
      danger: "<strong>Very Short Password</strong><br/>Make your password longer (at least 8 characters)."
    },
    uppercase: {
      success: "<strong>Uppercase Letters</strong>",
      warning: "<strong>Uppercase Letters</strong><br/>Try adding more uppercase letters.",
      danger: "<strong>Missing Uppercase Letters</strong><br/>Add uppercase letters."
    },
    lowercase: {
      success: "<strong>Lowercase Letters</strong>",
      warning: "<strong>Lowercase Letters</strong><br/>Try adding more lowercase letters",
      danger: "<strong>Missing Lowercase Letters<br/>Add lowercase letters."
    },
    digits: {
      success: "<strong>Numbers</strong>",
      warning: "<strong>Numbers</strong><br/>Try adding more numbers",
      danger: "<strong>Missing Numbers</strong><br/>Add numbers"
    },
    symbols: {
      success: "<strong>Symbols</strong>",
      warning: "<strong>Symbols</strong><br/>Try adding more symbols (such as !, @, #, etc.)",
      danger: "<strong>Missing Symbols</strong></strong><br/>Add symbols (such as !, @, #, etc.)"
    },
    repeats: {
      success: "No <strong>Repeating Patterns</strong>",
      warning: "<strong>Repeating Patterns</strong><br/>Try using more variety of letters, numbers or symbols.",
      danger: "<strong>Repeating Patterns</strong><br/>Do not use repeating characters (such as 999, XXX, etc.)"
    },
    sequence: {
      success: "No <strong>Sequences</strong>",
      warning: "<strong>Sequences</strong><br/>Try using more variety of letters, numbers or symbols.",
      danger: "<strong>Sequences</strong><br/>Do not use sequences (such as 123, ABC, qwerty, etc.)"
    },
    common: {
      success: "<strong>Unique Password</strong>",
      warning: "<strong>Common Password</strong><br/>Try using a unique password. This is a commonly used password.",
      danger:
        '<strong>Common Password</strong><br/>Do not use common passwords (such as "password", "querty", or "football".)'
    }
  };

  const passwordInputRef = useRef(null);
  const [showMessages, setShowMessages] = useState(false);
  const [hackTime, setHackTime] = useState(null);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [activeImage, setActiveImage] = useState(0);

  // NOTE: we are mapping both "Strong" and "VeryStrong" to the "success" message
  const StatusMessages = (props) => {
    // console.log("StatusMessages(): ", passwordChecks);
    if (!passwordChecks[props.check] || !messages[props.check]) {
      // console.warn("check or message undefined");
      return null;
    }
    // console.log("StatusMessages check: " + props.check + ", value: " + passwordChecks[props.check]);
    return (
      <>
        {(passwordChecks[props.check] === StatusEnum.VeryStrong || passwordChecks[props.check] === StatusEnum.Strong) &&
          messages[props.check]?.success && (
            <div className="d-flex align-items-start">
              <Icon class="mr-2 text-success fa-sm mt-2" name="check-circle" lib="fas" />
              <small
                id="password-strength-help"
                className="form-text text-muted"
                dangerouslySetInnerHTML={{ __html: messages[props.check]?.success }}
              />
            </div>
          )}
        {passwordChecks[props.check] === StatusEnum.Weak && messages[props.check]?.warning && (
          <div className="d-flex align-items-start">
            <Icon class="mr-2 text-warning fa-sm mt-2" name="exclamation-circle" lib="fas" />
            <small
              id="password-strength-help"
              className="form-text text-muted"
              dangerouslySetInnerHTML={{ __html: messages[props.check]?.warning }}
            />
          </div>
        )}
        {passwordChecks[props.check] === StatusEnum.VeryWeak && messages[props.check]?.danger && (
          <div className="d-flex align-items-start">
            <Icon class="mr-2 text-danger fa-sm mt-2" name="times-circle" lib="fas" />
            <small
              id="password-strength-help"
              className="form-text text-muted"
              dangerouslySetInnerHTML={{ __html: messages[props.check].danger }}
            />
          </div>
        )}
      </>
    );
  };

  const [progressBarClass, setProgressBarClass] = useState("bg-danger");

  // NOTE: the passwordStrength value used at each interval is base upon actual password testing, not arbitrary or equivalent range values
  useEffect(() => {
    if (showMessages) {
      if (passwordStrength >= 85) {
        setProgressBarClass("bg-success");
        setActiveImage(4);
      } else if (passwordStrength >= 60) {
        setProgressBarClass("bg-info");
        setActiveImage(3);
      } else if (passwordStrength >= 50) {
        setProgressBarClass("bg-warning");
        setActiveImage(2);
      } else {
        // if (passwordStrength >= 0 < 50)
        setProgressBarClass("bg-danger");
        setActiveImage(1);
      }
    } else {
      setActiveImage(0);
      setProgressBarClass("bg-danger");
    }
  }, [passwordStrength, showMessages]);

  // It was decided not to scroll to the input.
  // useEffect(() => {
  //   passwordInputRef.current.focus();
  // }, []);

  const sequences = [
    "abc",
    "abcd",
    "bcde",
    "cdef",
    "defg",
    "efgh",
    "fghi",
    "ghij",
    "hijk",
    "ijkl",
    "jklm",
    "klmn",
    "lmno",
    "mnop",
    "nopq",
    "opqr",
    "pqrs",
    "qrst",
    "rstu",
    "stuv",
    "tuvw",
    "uvwx",
    "vwxy",
    "wxyz",
    "xyz",
    "qwerty",
    "yuiop",
    "asdf",
    "fghj",
    "hjkl",
    "zxcv",
    "1qaz",
    "2wsx",
    "3edc",
    "4rfv",
    "5tgb",
    "6yhn",
    "7ujm",
    "012",
    "123",
    "234",
    "345",
    "456",
    "567",
    "678",
    "789",
    "890"
  ];

  // NOTE: do not modify these regular expressions!
  const regexLower = new RegExp(/[a-z]/, "g"); // lowercase a-z
  const regexUpper = new RegExp(/[A-Z]/, "g"); // uppercase A-Z
  const regexDigits = new RegExp(/[0-9]/, "g"); // digits 0-9
  const regexSymbols = new RegExp(/[\W_]/, "g"); // symbols (non-words; any character not a-z, A-Z, 0-9, or underscore)
  const regexRepeats = new RegExp(/(\S)(\1{2,})/, "g"); // 3 or more (non-whitespace) repeating characters, e.g. "AAA", or "999"

  function checkRepeats(str) {
    if (!str) return false;
    // replace spaces with underscores
    str = str.replace(/\s+/g, "_");
    return regexRepeats.test(str);
  }

  // Object to keep track of status for each check on the password
  const [passwordChecks, setPasswordChecks] = useState({
    length: null,
    uppercase: null,
    lowercase: null,
    digits: null,
    symbols: null,
    repeats: null,
    sequence: null,
    common: null
  });

  useEffect(() => {
    calcPasswordStrength();
  }, [passwordChecks]);

  // Enum for the status of each password check
  const StatusEnum = Object.freeze({
    VeryStrong: "very_strong", // 100 points
    Strong: "strong", // 67 points
    Weak: "weak", // 33 points
    VeryWeak: "very_weak" // 0 points
  });

  const calcPasswordStrength = () => {
    // console.log("passwordChecks: ", passwordChecks);

    let checkCount = 0;
    let subTotal = 0;
    Object.entries(passwordChecks).forEach((entry) => {
      const [key, val] = entry;
      if (val) {
        // console.log("getting score for [" + key + "] ");
        switch (val) {
          case StatusEnum.VeryStrong:
            // console.log("got very_strong");
            subTotal += 100;
            break;
          case StatusEnum.Strong:
            // console.log("got strong");
            subTotal += 67;
            break;
          case StatusEnum.Weak:
            // console.log("got weak");
            subTotal += 33;
            break;
          default:
            // console.log("default to very_weak");
            // StatusEnum.Weak)
            subTotal += 0;
        }
        // console.log("subTotal: " + subTotal);
        checkCount++;
      }
    });
    if (checkCount > 0) {
      const score = Math.round(subTotal / checkCount);
      // console.log("setting password strength to: " + score);
      setPasswordStrength(score);
    } else {
      setPasswordStrength(0);
    }
  };

  const checkPassword = (passwordInputValue) => {
    // console.log('checkPassword() entry: "' + passwordInputValue + '"');

    // get password length
    const passwordLength = passwordInputValue.length;

    if (passwordLength < 1) {
      // reset all checks
      setPasswordChecks({
        length: null,
        uppercase: null,
        lowercase: null,
        digits: null,
        symbols: null,
        repeats: null,
        sequence: null,
        common: null
      });
      setShowMessages(false);
      setPasswordStrength(0);
    } else {
      // create a local copy of the passwordChecks so that we are not asynchronously overwriting state multiple times in the is function.
      const tempPasswordChecks = { ...passwordChecks };

      // LENGTH CHECK
      // console.log("passwordLength: " + passwordLength);
      if (passwordLength >= 16) {
        tempPasswordChecks.length = StatusEnum.VeryStrong;
      } else if (passwordLength >= 14) {
        // suggest 12
        tempPasswordChecks.length = StatusEnum.Strong;
      } else if (passwordLength >= 8) {
        tempPasswordChecks.length = StatusEnum.Weak;
      } else if (passwordLength >= 1) {
        tempPasswordChecks.length = StatusEnum.VeryWeak;
      }
      // no need to check anything else if there aren't enough characters yet
      if (passwordLength >= 3) {
        setShowMessages(true);

        // get character counts for lowercase, uppercase, digits and symbols, default to 0 (empty array)
        const lowercaseCount = (passwordInputValue.match(regexLower) || []).length;
        const uppercaseCount = (passwordInputValue.match(regexUpper) || []).length;
        const digitCount = (passwordInputValue.match(regexDigits) || []).length;
        const symbolCount = (passwordInputValue.match(regexSymbols) || []).length;

        // LOWERCASE CHECK
        // console.log("lowercaseCount: " + lowercaseCount);
        if (lowercaseCount >= 8) {
          tempPasswordChecks.lowercase = StatusEnum.VeryStrong;
        } else if (lowercaseCount >= 4) {
          tempPasswordChecks.lowercase = StatusEnum.Strong;
        } else if (lowercaseCount >= 1) {
          tempPasswordChecks.lowercase = StatusEnum.Weak;
        } else {
          tempPasswordChecks.lowercase = StatusEnum.VeryWeak;
        }

        // UPPERCASE CHECK
        // console.log("uppercaseCount: " + uppercaseCount);
        if (uppercaseCount >= 8) {
          tempPasswordChecks.uppercase = StatusEnum.VeryStrong;
        } else if (uppercaseCount >= 4) {
          tempPasswordChecks.uppercase = StatusEnum.Strong;
        } else if (uppercaseCount >= 1) {
          tempPasswordChecks.uppercase = StatusEnum.Weak;
        } else {
          tempPasswordChecks.uppercase = StatusEnum.VeryWeak;
        }

        // DIGIT CHECK
        // console.log("digitCount: " + digitCount);
        if (digitCount >= 4) {
          tempPasswordChecks.digits = StatusEnum.VeryStrong;
        } else if (digitCount >= 2) {
          tempPasswordChecks.digits = StatusEnum.Strong;
        } else if (digitCount >= 1) {
          tempPasswordChecks.digits = StatusEnum.Weak;
        } else {
          tempPasswordChecks.digits = StatusEnum.VeryWeak;
        }

        // SYMBOL CHECK
        // console.log("symbolCount: " + symbolCount);
        if (symbolCount >= 3) {
          tempPasswordChecks.symbols = StatusEnum.VeryStrong;
        } else if (symbolCount >= 2) {
          tempPasswordChecks.symbols = StatusEnum.Strong;
        } else if (symbolCount >= 1) {
          tempPasswordChecks.symbols = StatusEnum.Weak;
        } else {
          tempPasswordChecks.symbols = StatusEnum.VeryWeak;
        }

        // REPEATS CHECK
        if (checkRepeats(passwordInputValue.toLowerCase())) {
          // console.log("repeated characters");
          // TODO: should this be Weak or VeryWeak?
          tempPasswordChecks.repeats = StatusEnum.Weak;
        } else {
          // console.log("no repeats");
          tempPasswordChecks.repeats = StatusEnum.VeryStrong;
        }

        // SEQUENCE CHECK
        let sequenceFound = false;
        sequences.forEach((seq) => {
          if (passwordInputValue.toLowerCase().includes(seq)) {
            // console.log("sequence found: " + seq);
            sequenceFound = true;
          }
        });
        if (sequenceFound) {
          // TODO: should this be Weak or VeryWeak?
          tempPasswordChecks.sequence = StatusEnum.Weak;
        } else {
          // console.log("no sequences");
          tempPasswordChecks.sequence = StatusEnum.VeryStrong;
        }

        if (passwordLength >= 5) {
          let timeToCrack = null;
          let isCommon = false;
          // check against common passwords data list, if it is found there, use the time to crack found there
          for (const cpw of commonPasswordData) {
            var re = new RegExp(`^${cpw.Password}$`);
            if (re.test(passwordInputValue.toLowerCase())) {
              // console.log("Common password found: " + cpw.Password);
              isCommon = true;
              timeToCrack = cpw.Time_to_crack;
              tempPasswordChecks.common = StatusEnum.VeryWeak;
              break;
            }
          }
          if (isCommon) {
            tempPasswordChecks.common = StatusEnum.VeryWeak;
          } else {
            tempPasswordChecks.common = StatusEnum.VeryStrong;
            // console.log("No common password");
          }
          // NOTE: not sure how to differentiate timeToCrack and isCommon, NOT using Mellt for now
          // otherwise use Mellt to calculate the number of days to crack.
          if (!timeToCrack) {
            timeToCrack = Mellt(passwordInputValue);
          }
          setHackTime(timeToCrack);
        }
        setPasswordChecks({ ...tempPasswordChecks });
      } else {
        setShowMessages(false);
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <h4>
        Use this tool to check the strength of your password by entering it in the field below. This password testing
        tool evaluates the security of your password based on the uniqueness and combination of letters (uppercase,
        lowercase), numbers and symbols (!, @, #, etc). To learn how to make your password more secure, see how you can
        protect yourself with other tools and best practices.
      </h4>
      <section id="password-testing-tool-section">
        <div className="row">
          <div className="col-md-6">
            <form id="password-strength-form" autoComplete="new-password">
              <div className="form-group">
                <label htmlFor="password-strength-input" className="font-weight-bold">
                  Enter your Password
                </label>
                <div className="position-relative">
                  <input
                    ref={passwordInputRef}
                    type={showPassword ? "text" : "password"}
                    className="form-control mb-2 border-radius-6"
                    id="password-strength-input"
                    aria-describedby="password-strength-help"
                    autoComplete="off"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    onChange={(e) => {
                      checkPassword(e.target.value);
                    }}
                  />
                  <div
                    onClick={(e) => {
                      toggleShowPassword();
                    }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "0.25em",
                      transform: "translateY(-50%)",
                      paddingLeft: "0.25em",
                      paddingRight: "0.25em"
                    }}
                  >
                    <Icon lib="fal" name={showPassword ? "eye-slash" : "eye"} />
                  </div>
                </div>
                <div className="progress border-radius-3 mb-3">
                  <div
                    className={`progress-bar ${progressBarClass} border-radius-3`}
                    role="progressbar"
                    style={{ width: `${passwordStrength}%` }}
                    aria-valuenow={passwordStrength}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {passwordStrength}
                  </div>
                </div>
                {showMessages ? (
                  Object.keys(passwordChecks).map((check, index) => (
                    <div key={index} className="mb-3">
                      <StatusMessages check={check} />
                    </div>
                  ))
                ) : (
                  <div className="d-flex align-items-start mb-3">
                    <Icon class="mr-2 text-primary fa-sm mt-2" name="info-circle" lib="fas" />
                    <small id="password-strength-help" className="form-text">
                      Please note that you should <strong>never</strong> put personal information in your password
                      (names, addresses, birthdays, etc.)
                    </small>
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="col-md-6">
            <div className="d-flex flex-column align-items-center">
              <h2 className={activeImage === 0 ? "d-block" : "d-none"}>Test Your Password</h2>
              <h2 className={activeImage === 1 ? "d-block" : "d-none"}>Very Weak Password</h2>
              <h2 className={activeImage === 2 ? "d-block" : "d-none"}>Weak Password</h2>
              <h2 className={activeImage === 3 ? "d-block" : "d-none"}>Strong Password</h2>
              <h2 className={activeImage === 4 ? "d-block" : "d-none"}>Very Strong Password</h2>
              <img
                src="../../../images/icons/laptop-password-test.svg"
                alt="An open laptop"
                className={activeImage === 0 ? "d-block" : "d-none"}
                height="300"
              />
              <img
                src="../../../images/icons/laptop-password-very-weak.svg"
                alt="A laptop that is highly at risk of being hacked"
                className={activeImage === 1 ? "d-block" : "d-none"}
                height="300"
              />
              <img
                src="../../../images/icons/laptop-password-weak.svg"
                alt="A laptop that is at risk of being hacked"
                className={activeImage === 2 ? "d-block" : "d-none"}
                height="300"
              />
              <img
                src="../../../images/icons/laptop-password-strong.svg"
                alt="A laptop that is moderately secured from being hacked"
                className={activeImage === 3 ? "d-block" : "d-none"}
                height="300"
              />
              <img
                src="../../../images/icons/laptop-password-very-strong.svg"
                alt="A laptop that is highly secured from being hacked"
                className={activeImage === 4 ? "d-block" : "d-none"}
                height="300"
              />
              {hackTime && (
                <>
                  <div className="mt-3 mb-1">It would take a hacker about this long to hack your password:</div>
                  <h3 className="font-weight-bold">{hackTime}</h3>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <h2>How the Password Testing Tool Works</h2>
      <p>
        This password Testing Tool checks for secure password best practices and assigns requirement a score. That score
        fits into either a very weak, weak, strong or very strong password and also gives a time-frame that a hacker
        would probably take to hack it. This tool checks for lowercase and uppercase letters, numbers and symbols and
        finds any patterns or other common password mistakes.
      </p>
      <p>
        <strong>Any password you enter here is not stored anywhere</strong> - testing is all done on this page and no
        data is sent or saved from this tool.
      </p>
      <h2>How We Protect You</h2>
      <ol className="bold-bullets">
        <li>
          <span className="h5">Credit File Monitoring</span>
          <br />
          With a Rewards, Premium Rewards, or Interest Checking account, you get access to identity theft monitoring and
          resolution to protect you and the things in life that matter most. Alerts may be sent directly to your mobile
          phone via text.
          <br />
          <br />
          <Link
            id="blog-rewards-checking-faq-link"
            className="text-decoration-none"
            to="/personal-banking/rewards-checking-faq"
          >
            How to Access Rewards
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </li>
        <li>
          <span className="h5">Secure Online and Mobile Banking</span>
          <br />
          Easily monitor inventory and manage sales. Solutions are available for small businesses up to large enterprise
          with multiple locations.
          <br />
          <br />
          <Link
            to="/personal-banking/online-banking"
            className="text-decoration-none"
            id="blog-internal-online-banking-link"
          >
            WaFd Bank Online Banking
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </li>
      </ol>
      <h2>How You Can Protect Yourself</h2>
      <ol className="bold-bullets">
        <li>
          <span className="h5">Research Best Practices</span>
          <br />
          Your account is already protected, but you can take additional steps to further secure your accounts. And, as
          your level of protection rises, so does your meter level, helping you feel more confident about the safety of
          your personal and financial information with WaFd Bank.
          <br />
          <br />
          <a
            href="https://www.security.org/how-secure-is-my-password/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none"
            id="blog-external-password-tools-link"
          >
            Other Secure Password Tools
            <Icon name="arrow-right" class="ml-1" />
          </a>
        </li>
        <li>
          <span className="h5">Use a Virtual Private Network</span>
          <br />
          While passwords keep unauthorized users out of accounts, Internet Service Providers can still track a user's
          online activity as well as their devices' private IP addresses. The only way to hide web activity and IP
          addresses is to connect not directly to a public Wi-Fi network, but instead to a VPN, which stands for Virtual
          Private Network.
          <br />
          <br />
          <a
            href="https://www.security.org/vpn/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none"
            id="blog-external-research-vpn-link"
          >
            Research VPNs
            <Icon name="arrow-right" class="ml-1" />
          </a>
        </li>
        <li>
          <span className="h5">Use a Password Manager</span>
          <br />
          Password managers store users' usernames and passwords in encrypted vaults, requiring only master passwords or
          biometrics to log into accounts.
          <br />
          <br />
          <a
            href="https://www.techradar.com/best/password-manager"
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none"
            id="blog-external-password-manager-link"
          >
            Find a Password Manager
            <Icon name="arrow-right" class="ml-1" />
          </a>
        </li>
        <li>
          <span className="h5">Report Suspicious Activity</span>
          <br />
          If you suspect fraudulent activity on your account, you can report it and freeze your debit card to prevent
          new purchases and ATM transactions. It's easy to freeze or unfreeze your debit card in seconds via the mobile
          app, online or over the phone.
          <br />
          <br />
          Call to Report Suspicious Activity
          <br />
          <a href="tel:800-324-9375" className="text-decoration-none" id="blog-c3-tel-link">
            <Icon name="phone-alt" class="mr-1" />
            800-324-9375
          </a>
        </li>
      </ol>
    </>
  );
};
export default PasswordTestingTool;
